import i18next from 'i18next';

i18next.addResources('es', 'ModalOrderDetail', {
  order: 'Orden {{id}}',
  doctorId: 'ID del doctor: ',
  doctorName: 'Nombre del doctor: ',
  doctorEmail: 'Email del doctor: ',
  patientName: 'Nombre del paciente: ',
  patientId: 'ID del paciente: ',
  manufactureDate: 'Fecha de Manufactura: ',
  approvalDate: 'Fecha de Aprobación: ',
  estimatedValue: 'Valor estimado: ',
  save: 'GUARDAR',
  saving: 'GUARDANDO',
  cancel: 'CANCELAR',
  state: 'Estado',
});
