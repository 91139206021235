import { FC, lazy, Suspense } from 'react';
import { createGlobalStyle } from 'styled-components';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '../config/api';
import routes from '../constants/routes';
import ConnectionError from './components/ConnectionError/ConnectionError';

const GlobalStyles = createGlobalStyle`
  html, body {
    background-color: #F5F5F5;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
`;

const LazyLogin = lazy(() => import('./pages/Login/Login'));

const LazyChangePassword = lazy(
  () => import('./pages/ChangePassword/ChangePassword'),
);

const LazyRecoverPassword = lazy(
  () => import('./pages/RecoverPassword/RecoverPassword'),
);

const LazyDashboard = lazy(() => import('./pages/Dashboard/Dashboard'));

const LazyUsers = lazy(() => import('./pages/Users/Users'));

const LazyUserForm = lazy(() => import('./pages/UserForm/UserForm'));

const App: FC = () => (
  <Router>
    <GlobalStyles />
    <ConnectionError />
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path={routes.LOGIN}>
          <LazyLogin />
        </Route>
        <Route exact path={routes.RECOVER_PASSWORD}>
          <LazyRecoverPassword />
        </Route>
        <Route exact path={routes.CHANGE_PASSWORD}>
          <LazyChangePassword />
        </Route>
        <Route exact path={routes.DASHBOARD}>
          <LazyDashboard />
        </Route>
        <Route exact path={routes.USERS}>
          <LazyUsers />
        </Route>
        <Route exact path={routes.USER_EDIT}>
          <LazyUserForm />
        </Route>
        <Route exact path={routes.USER_CREATE}>
          <LazyUserForm />
        </Route>
      </Switch>
    </Suspense>
  </Router>
);

export default App;
