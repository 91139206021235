import i18next from 'i18next';

i18next.addResources('es', 'Errors', {
  error: 'Error',
  required: 'Este campo es requerido.',
  connectionErrorTitle: 'Error de conexión.',
  connectionErrorDescription:
    'Estamos teniendo dificultades con el servidor. Espere unos minutos e intente nuevamente.',
  connectionErrorLabel: 'Aceptar',
  invalidEmail: 'Mail inválido.',
  repeatedEmail: 'El mail ya está usado.',
  repeatedEmailErrorMessage: 'email already used',
  repeatedTreatmentLocationNumber: 'El agrupador de ordenes ya está usado.',
  repeatedTreatmentLocationNumberErrorMessage:
    'treatment location number already used',
  noMatch: 'Los campos no coinciden',
  invalid: 'En campo es inválido',
});
