import i18next from 'i18next';

i18next.addResources('es', 'ModalAltaEmbarque', {
  title: 'Subir nuevo embarque',
  fileErrorTitle: 'Error',
  fileErrorDescription: 'El archivo tiene un formato inválido',
  uploadFile: 'Archivo',
  trackingNumber: 'Nro tracking',
  releaseDate: 'Fecha de salida',
  shipmentNumber: 'Nro embarque',
  cancel: 'CANCELAR',
  import: 'IMPORTAR',
  loading: 'Cargando...',
});
