import { FC, useCallback, useEffect, useState } from 'react';
import { useI18next } from '../../../config/i18next';
import { ErrorModal } from '../Modal/Modal';

export const showError = (data: any) => {
  const errorEvent = new CustomEvent('connection-error', { detail: data });
  document.dispatchEvent(errorEvent);
};

const ConnectionError: FC = () => {
  const [t] = useI18next();
  const [error, setError] = useState(null);
  const onClose = useCallback(() => {
    setError(null);
  }, []);
  useEffect(() => {
    document.addEventListener('connection-error', (event: CustomEvent) => {
      setError(event.detail);
    });
  }, []);

  return (
    <ErrorModal
      title={error?.title || t('Errors:connectionErrorTitle')}
      description={error?.description || t('Errors:connectionErrorDescription')}
      label={error?.label || t('Errors:connectionErrorLabel')}
      isOpen={error}
      onClose={onClose}
    />
  );
};

export default ConnectionError;
