const colors = {
  black: '#000000',
  blue: '#2F80ED',
  darkBlue: '#002E5A',
  darkerBlue: '#03509A',
  lightBlue: '#009ACE',
  white: '#FFFFFF',
  border: '#E5E5E5;',
  background: '#FAFAFA',
  inputBorder: '#DADADA',
  placeHolder: '#000000',
  dayColor: '#4F4F4F',
  grey: '#CCC',
  red: '#EE0202',
  data: '#3C3844',
};

export default colors;
