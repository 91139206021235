import i18next from 'i18next';

i18next.addResources('es', 'Users', {
  title: 'Usuarios',
  createUserLabel: 'Crear usuario',
  name: 'Nombre',
  role: 'Rol',
  email: 'Email',
  state: 'Estado',
  actions: 'Acciones',
  active: 'Activo',
});
