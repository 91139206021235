import { FC } from 'react';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import { ButtonProps } from './types';

const Button: FC<ButtonProps> = ({ children, ...props }) => (
  <button {...props}>{children}</button>
);

const BaseButton = styled(Button)`
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  min-width: 140px;
  outline: none;
  padding: 1.1em 2em;
  text-transform: uppercase;
  width: 100%;
  :disabled {
    opacity: 0.5;
  }
`;
BaseButton.defaultProps = {
  type: 'button',
};

export const BlueButton = styled(BaseButton)`
  background-color: ${colors.blue};
  color: ${colors.white};
`;

export const BlackButton = styled(BaseButton)`
  background-color: ${colors.black};
  color: ${colors.white};
  width: 100%;
`;

export const FilterButton = styled(BaseButton)`
  background-color: ${colors.black};
  color: ${colors.white};
  margin: 4rem 1.5rem 0rem;
  min-width: 100px;
  padding: 0.8em 2em;
  width: 5%;
`;

export const BlueDarkButton = styled(BaseButton)`
  background-color: ${colors.darkBlue};
  color: ${colors.white};
`;

export const WhiteGridButton = styled(BaseButton)<{ handleColor: boolean }>`
  background-color: ${(props) =>
    props.handleColor ? colors.grey : colors.white};
  border: none;
  border-radius: 0;
  color: ${colors.black};
  font-size: 1.2rem;
  padding: 0.6em 0em;
  text-transform: none;
  width: 50%;
`;

export const GreyGridButton = styled(WhiteGridButton)<{ handleColor: boolean }>`
  background-color: ${(props) =>
    props.handleColor ? colors.white : colors.grey};
`;

export const TransparentButton = styled(Button)`
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
`;

export const NavBarButtons = styled(Button)`
  background-color: Transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-align: right;
`;
