import i18next from 'i18next';

i18next.addResources('es', 'Login', {
  user: 'Usuario',
  password: 'Contraseña',
  forgotPassword: 'OLVIDÉ MI CONTRASEÑA',
  login: 'INICIAR SESIÓN',
  errorLogin: 'El email o la contraseña es inválido.',
  error: 'Error',
});
