import i18next from 'i18next';

i18next.addResources('es', 'ShipmentGrid', {
  shipmentNumber: 'Nro embarque',
  trackingNumber: 'Tracking',
  shipmentDate: 'Fecha de embarque',
  stateUpdate: 'Ultima modificación',
  eta: 'ETA',
  ordersQuantity: 'Órdenes',
  state: 'Estado',
  detail: 'Detalle',
});
