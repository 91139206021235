import i18next from 'i18next';

i18next.addResources('es', 'OrdersFilters', {
  shipmentNumber: 'Nro embarque',
  patientID: 'ID Paciente',
  doctor: 'Doctor',
  patientName: 'Paciente',
  state: 'Estado',
  select: 'Todos',
});
