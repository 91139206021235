import i18next from 'i18next';

i18next.addResources('es', 'UserForm', {
  back: 'Volver',
  name: 'Nombre',
  rol: 'Rol',
  email: 'Email',
  phoneNumber: 'Teléfono',
  treatmentLocationNumber: 'Agrupador de orden',
  save: 'Guardar',
});
