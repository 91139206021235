import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import App from './app/App';
import { GlobalStateProvider } from './config/store';

ReactDOM.render(
  <StrictMode>
    <GlobalStateProvider>
      <App />
    </GlobalStateProvider>
  </StrictMode>,
  document.getElementById('app'),
);
