import i18next from 'i18next';

i18next.addResources('es', 'ModalShipmentDetail', {
  shipment: 'Embarque',
  shipmentNumber: 'Nro. de embarque: ',
  trackingNumber: 'Nro. de tracking: ',
  orderQuantity: 'Cantidad de órdenes: ',
  shipmentDate: 'Fecha de embarque: ',
  state: 'Estado: ',
  cancel: 'CANCELAR',
  save: 'GUARDAR',
  saving: 'GUARDANDO...',
});
