import i18next from 'i18next';

i18next.addResources('es', 'OrdersGrid', {
  shipmentNumber: 'Nro embarque',
  doctor: 'Doctor',
  pacient: 'Paciente',
  pacientID: 'ID Paciente',
  eta: 'ETA',
  product: 'Producto',
  orderSap: 'Orden SAP',
  state: 'Estado',
  detail: 'Detalle',
});
